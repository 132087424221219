<template>
<footer class="w">
  <!-- <div class="link">
    <h4>友情链接</h4>
    <ul>
      <li><a target="_blank" title="欧菲斯办公伙伴" href="http://www.officemate.cn/">欧菲斯办公伙伴</a></li>
      <li><a target="_blank" title="福分通" href="https://www.fufentong.com/">福分通</a></li>
      <li><a target="_blank" title="企修通" href="http://www.matefix.cn/">企修通</a></li>
      <li><a target="_blank" title="批发购" href="http://pfg.officemate.cn/">批发购</a></li>
      <li><a target="_blank" title="企业购" href="http://www.qygou.cn/">企业购</a></li>
      <li><a target="_blank" title="合约在线" href="http://b.officemate.cn/login?type=PC">合约在线</a></li>
      <li><a target="_blank" title="工品伙伴" href="http://www.matemro.com/web/">工品伙伴</a></li>
    </ul>
  </div> -->
  <div class="contactus mt30">
    <div>
      <h4>联系我们</h4>
      <div class="msg">
        <p>运营企业：渠县渠江街道金宝宝自助棋牌馆（个体工商户）</p>
        <p>咨询电话：17623855219</p>
        <p>电子邮箱：891252098@qq.com</p>
        <p>联系地址：四川省达州市渠县 渠江街道锦茂华府郦苑D栋2-1号</p>
      </div>
    </div>
    <div class="pic">
      <div class="img_box">
        <img src="@/assets/imgs/OR_code.jpg" alt="">
      </div>
      <p>扫一扫，小程序预约</p>
    </div>
  </div>
  <!-- 版权 -->
  <div class="copy mt30">
    <!-- <p>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">
        蜀ICP备2023034236号
      </a>
    </p> -->
    <p style="width:300px;margin:0 auto;">
      <img src="@/assets/imgs/beian.png" alt="" srcset="">
      <a target="_blank" href="http://beian.miit.gov.cn" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;" />
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">蜀ICP备2023034236号-2
        </p>
      </a>
    </p>
    <p>版权所有&copy;CopyRight2019-2020&emsp;渠县渠江街道金宝宝自助棋牌馆（个体工商户）</p>
  </div>
</footer>
</template>

<script>
export default {
  name: 'footer-div',
}
</script>

<style lang="less" scoped>
.w {
  width: 1190px;
  margin: 0 auto;
}

ul {
  list-style: none;
}

footer {
  padding-bottom: 20px;

  .link {
    padding-top:40px ;
    h4 {
      font-weight: bold;
      font-size: 18px;
      line-height: 36px;

    }

    ul {
      display: flex;
      padding:0;
      li {
        a {
          font-size: 14px;
          line-height: 20px;
          display: block;
          padding: 0 15px;
          text-align: center;
          color: #333333;
          text-decoration: none;
        }

        a:hover {
          color: #01A252;
        }

        a:first-child {
          padding-left: 0;
        }
      }
    }
  }

  .contactus {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    h4 {
      font-weight: bold;
      font-size: 18px;
      line-height: 36px;
    }

    .msg {
      p {
        font-size: 14px;
        line-height: 38px;
        color: #333;
      }
    }

    .pic {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img_box {
        width: 160px;
        height: 160px;
        margin-bottom: 20px;
        img{
          width: 100%;
          height: 100%;
        }
      }

      p {
        color: #666;
        font-size: 14px;
      }

    }
  }

  /* 版权 */
  .copy {
    color: #999;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    p{
      margin:0;
      a{
       color: rgb(147, 147, 147);
      }
    }
  }
}
</style>
