<template>
<div>
  <header class="header">
    <div class="subHeader w">

      <div class="left">
        <img src="@/assets/imgs/logo.png" alt="">
        <ul class="nav">
          <li>
            <router-link :class="this.$route.path=='/'?'active':''" to="/" title="首页">首页</router-link>
          </li>
          <!-- <li><a target="_blank" title="学习平台" href="http://peixun.pinshangtong.cn">学习平台</a></li> -->
          <li><a target="_blank" title="企业后台" href="https://admin.jbbmahjong.cn">企业后台</a></li>
          <!-- <li>
            <router-link :class="this.$route.path=='/aboutus'?'active':''" to="/aboutus" title="关于品商通">关于品商通</router-link>
          </li> -->
        </ul>
      </div>
      <div class="btn" @click="linkTo" v-if="$route.path!='/test'">
        立即试用
      </div>
    </div>
  </header>
  <!-- <div style="height:60px"></div> -->
</div>
</template>

<script>
export default {
  methods: {
    linkTo() {
      window.open('https://admin.jbbmahjong.cn')
      // this.$router.push('/test')
    }
  }
}
</script>

<style lang="less" scoped>
/* 头部 */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 60px;
  // line-height: 60px;
  /* 
  top: 0; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.header .left {
  display: flex;
  align-items: center;
}



.header .left a {
  display: flex;
  align-items: center;
  color: #333;
}

.header .nav {
  display: flex;
  margin-left: 25px;
}

.header .nav li {
  text-align: center;
}

.header .nav li a {
  display: block;
  padding: 0 15px;
  text-align: center;
  font-weight: 500;
  // transition: all .5s ease;
  // transition: background .5s linear;
}

.header .nav li:hover a {
  background: #01A252;
  color: #fff;
}

.header .nav li a.active {
  background: #01A252;
  color: #fff;
}

.header .btn {
  width: 86px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #01A252;
  border-radius: 4px;
}

.header .btn {
  color: #fff;

}
</style>
